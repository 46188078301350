// header menu
export const headerEngMenu = [
  { name: "Home", path: "/" },
  { name: "Feature", path: "/feature" },
  { name: "Merchant", path: "/merchant" },
  { name: "Career", path: "/career" },
  // { name: "Promotion", path: "/promo" },
  { name: "FAQ", path: "/faqs" },
];

// header menu
export const headerMyMenu = [
  { name: "Halaman Utama ", path: "/" },
  { name: "Ciri", path: "/feature" },
  { name: "Peniaga", path: "/merchant" },
  { name: "Kerjaya", path: "/career" },
  // { name: "Promosi", path: "/promo" },
  { name: "Soalan Lazim", path: "/faqs" },
];

export const downloadEng = "Download";
export const downloadMy = "Muat Turun";
export const downloadVn = "Tải ứng dụng";

// header menu
export const headerVnMenu = [
  { name: "Trang chủ", path: "/" },
  { name: "Tính năng", path: "/tinh-nang" },
  { name: "Đối tác", path: "/doi-tac" },
  { name: "Khuyến mãi", path: "/khuyen-mai" },
  { name: "Liên kết ngân hàng", path: "/lien-ket-ngan-hang" },
  { name: "Trung tâm trợ giúp", path: "/faqs" },
];

// career link
export const careerMyLink = "https://careers.shopee.com.my";

// FAQ link
export const faqsVnLink =
  "https://help.cs.shopeepay.vn/portal?source=100";
